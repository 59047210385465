<template>
  <div class="space_pc">
    <Aside
      v-if="showAside && this.$store.state.division !== 'Building'"
      @closeAside="showAside = false"
      v-click-outside="onClickOutside"
    />
    <AsideBuilding
      v-if="showAside && this.$store.state.division === 'Building'"
      @closeAside="showAside = false"
      v-click-outside="onClickOutside"
    />
    <PlayerHeader v-if="$store.getters.isKisok" />
    <div v-else class="header">
      <div class="header_box">
        <div class="logo" @click="linkPlayer()" style="cursor: pointer">
          <img src="/media/img/logo.svg" alt="" />
        </div>
        <div class="right_menu">
          <div class="shop_info" v-if="$store.state.spaceNm != null && $store.state.division !== 'Building'">
            <img src="/media/img/header_shop.png" alt="" />
            <p style="margin-bottom: 0px" v-html="$store.state.spaceNm" />
          </div>
          <div class="alarm_box">
            <router-link
              :to="{
                name: 'Notice0'
              }"
            >
              <img src="/media/img/alarm.png" v-if="$store.state.noticeCount === 0" alt="" />
              <img src="/media/img/alarm_icon_active.svg" v-else alt="" />
            </router-link>
          </div>
          <div class="toggle" @click="showAside = true">
            <img src="/media/img/toggle.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>
<script>
import { defineComponent } from '@vue/composition-api';
import Aside from '@/layout/aside/Aside.vue';
import AsideBuilding from '@/layout/aside/AsideBuilding.vue';
import vClickOutside from 'v-click-outside';
import VueCookies from 'vue-cookies';
import router from '@/router';
import PlayerHeader from '@/layout/header/HeaderPlayer.vue';
import { getterUserType } from '@/assets/js/usertype';
import { getMySpaceList, subscribeSpaceList } from '@/service/api/profileApi.js';
import { getUserCase } from '@/service/api/userApi.js';
export default defineComponent({
  name: 'Header',
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    Aside,
    AsideBuilding,
    PlayerHeader
  },
  data() {
    return {
      showAside: false
    };
  },
  methods: {
    onClickOutside(event) {
      this.showAside = false;
    },
    async linkPlayer() {
      try {
        const { data } = await getUserCase(this.$cookies.get('userId'));
        const { resultCd, result } = data;

        if (result.trialYn === 'N' && result.spaceYn === 'Y' && !this.$cookies.get('spaceId')) {
          this.$router.push({ name: 'MySpace' });
          return;
        } else {
          this.$router.push({ name: 'PlayerView' });
        }
      } catch (error) {
        console.log(error);

        if (error.response) {
          console.log();
        }
      }
    }
  }
});
</script>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
